@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































::v-deep {
  .el-table__footer-wrapper {
    .has-gutter {
      height: 40px;
      font-family: 'PingFang Bold';

      .required-th::before {
        display: none;
      }

      td {
        text-align: center;
      }
    }
  }
}
